(function () {

    'use strict';

    angular.module('App')
        .component('contentCreatePopup', {
            template: require('./ContentCreatePopupComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$filter', '$http', '$timeout', '$scope', 'ToastFactory', 'Page', 'Menu',
                'PopupWrapperService', 'ContentWrapperService', 'Profile', 'ConfirmPopupService',
                'MentionService', 'TranslationService', 'LanguageSelectorService', 'ContentCreateService',
                'ContentSafetyWarningService',
                ContentCreatePopupController],
            bindings: {
                onUpdate: '&',
                onPopupRegistered: '<',
                moduleToken: '@'
            }
        });

    function ContentCreatePopupController($element, $filter, $http, $timeout, $scope, ToastFactory, Page, Menu,
                                          PopupWrapperService, ContentWrapperService, Profile, ConfirmPopupService,
                                          MentionService, TranslationService, LanguageSelectorService,
                                          ContentCreateService, ContentSafetyWarningService) {
        var ctrl = this, popup;
        let postChangeWatcher, loadingWatcher, categoryWatcher;

        ctrl.authorName = Profile.getProfile().Name;
        ctrl.translatedText = [];

        ctrl.close = close;
        ctrl.onOpen = onOpen;
        ctrl.isTextFilled = isTextFilled;
        ctrl.save = save;
        ctrl.update = update;
        ctrl.updateEndTime = updateEndTime;
        ctrl.translate = translate;
        ctrl.languageLoaded = languageLoaded;
        ctrl.permissionLoaded = permissionLoaded;

        ctrl.requireUserLanguage = !Page.getSettings().CulturesRework;

        function onOpen(data, popupCtrl) {
            ctrl.dataLoaded = false;
            popup = popupCtrl;
            ctrl.translateDisabled = true;
            ctrl.isFormValid = false;
            ctrl.settings = data.Settings;
            ctrl.categories = data.Categories;
            ctrl.cultures = LanguageSelectorService.updateLanguagesInfo(data.Cultures);
            ctrl.translationExcludedWords = [];
            ctrl.AccountModuleToken = data.AccountModuleToken;
            ctrl.navigationBadge = data.navigationBadge;
            ctrl.NotificationsEnabled = data.NotificationsEnabled;
            ctrl.LimitedRteToolset = data.LimitedRteToolset;
            ctrl.mode = data.mode || 'create';
            ctrl.CategoryToken = ctrl.mode === 'create' ? data.CategoryToken : data.Model?.CategoryToken;
            ctrl.showValidation = false;
            ctrl.showPermissionsEditor = !(ctrl.mode === 'edit' && !data.CanEditPermissions);

            ctrl.uploadUrl = Page.getSettings().MediaServerDomain + '/Upload'
            ctrl.uploadParams = {
                AccountToken: Profile.getProfile().AccountToken, UserToken: Profile.getProfile().UserToken
            }

            ctrl.permissionOptions = {
                departmentFilterType: data.Settings.PermissionSettingDepartmentType,
                requirePermissionId: data.Settings.PermissionSettingType,
                requireDepartment: data.RequireDepartmentFilter,
                matchAllGroups: ctrl.settings.PermissionSettingDefaultRequireAllUserGroups,
                userGroupsLimited: true // always true on the content module, as you can't assign roles you are not allowed to assign in other cases
            };

            ctrl.content = {
                Media: [],
                Title: '',
                Body: '',
                selectedLanguages: [],
                selectedLanguageIndex: 0,
                Culture: _.find(ctrl.cultures, {'CultureId': data.UserCultureId}),
                Point: ctrl.settings.Points ? ctrl.settings.DefaultPoint : null,
                SendNotification: data.SendNotification,
                SendNotificationEditable: data.SendNotificationEditable,
                SendNotificationEnabled: data.SendNotificationEnabled,
                SendPushNotification: data.SendPushNotification,
                SendPushNotificationEditable: data.SendPushNotificationEditable,
                SendPushNotificationEnabled: data.SendPushNotificationEnabled,
                AllowIgnoringTimeFilterEnabled: data.AllowIgnoringTimeFilterEnabled,
                Schedule: data.Schedule,
                IsTimeInTimeframes: data.IsTimeInTimeframes,
                MatchAllGroups: ctrl.settings.PermissionSettingDefaultRequireAllUserGroups,
                DefaultMatchAllGroups: ctrl.settings.PermissionSettingDefaultRequireAllUserGroups,
                MultiplePermissionSetsEnabled: ctrl.settings.MultiplePermissionSetsEnabled,
                ModuleArchiveEnabled: ctrl.settings.AccountArchiveEnabled && ctrl.settings.ArchiveEnabled,
                ArchiveEnabled: ctrl.settings.AccountArchiveEnabled && ctrl.settings.ArchiveEnabled && ctrl.settings.ArticleByDefaultArchiveEnabled,
            };

            ctrl.content.StartTime = moment().startOf('minute').toDate();
            updateEndTime();

            if (ctrl.mode === 'edit') {
                const archivedMode = ctrl.settings.AccountArchiveEnabled && ctrl.settings.ArchiveEnabled && typeof data.ArchiveEnabled !== 'boolean' ?
                    ctrl.settings.ArticleByDefaultArchiveEnabled : data.ArchiveEnabled;

                ctrl.isFormValid = true;
                ctrl.content = {
                    Media: data.Model.Media || [],
                    Title: data.Model.Title,
                    Body: data.Model.Body,
                    Culture: _.find(data.Cultures, {'CultureId': data.Model.CultureId}),
                    Point: data.Model.Point,
                    StartTime: data.Model.StartTime ? moment(data.Model.StartTime).toDate() : null,
                    EndTime: data.Model.EndTime ? moment(data.Model.EndTime).toDate() : null,
                    ContentToken: data.Model.ContentToken,
                    SendNotification: data.Model.SendNotification,
                    SendNotificationEditable: data.Model.SendNotificationEditable,
                    SendNotificationEnabled: data.Model.SendNotificationEnabled,
                    SendPushNotification: data.Model.SendPushNotification,
                    SendPushNotificationEditable: data.Model.SendPushNotificationEditable,
                    SendPushNotificationEnabled: data.Model.SendPushNotificationEnabled,
                    HideSwipeToConfirm: data.Model.HideSwipeToConfirm,
                    DisableComments: data.Model.DisableComments,
                    DisableReactions: data.Model.DisableReactions,
                    ModuleArchiveEnabled: ctrl.settings.AccountArchiveEnabled && ctrl.settings.ArchiveEnabled,
                    ArchiveEnabled: archivedMode,
                    MultiplePermissionSetsEnabled: ctrl.settings.MultiplePermissionSetsEnabled,
                    Permissions: JSON.parse(data.Permissions)
                };

                if (data.LocaleList) {
                    ctrl.content.selectedLanguages = data.LocaleList.map(language => {
                        return {
                            Body: language.BodyText,
                            initialBody: language.BodyText,
                            CultureId: language.CultureId,
                            Title: language.Title,
                            initialTitle: language.Title,
                            AutoTranslated: language.AutoTranslated
                        }
                    })
                }
            }

            $scope.$watch('ctrl.content.selectedLanguages', () => {
                if (ctrl.content.selectedLanguages && ctrl.content.selectedLanguages.length) {
                    function isUserLanguageValid() {
                        return ctrl.content.selectedLanguages[0].Title && ctrl.content.selectedLanguages[0].Body
                    }

                    function isAdditionalLanguagesValid() {
                        return ctrl.content.selectedLanguages.slice(1).every(el => {
                            return (el.Title && el.Body || (!el.Title && !el.Body))
                        })
                    }

                    if (Page.getSettings().CulturesRework) {
                        ctrl.isFormValid =
                            ctrl.content.selectedLanguages.every(el => (el.Title && el.Body) || (!el.Title && !el.Body))
                            && ctrl.content.selectedLanguages.some(el => el.Title && el.Body);
                    } else {
                        ctrl.isFormValid = isUserLanguageValid() && isAdditionalLanguagesValid();
                    }
                }
            }, true)

            ctrl.translatedText = _.cloneDeep(ctrl.content.selectedLanguages);

            $scope.$watch(() => ctrl.content.selectedLanguages[ctrl.content.selectedLanguageIndex],
                (newVal) => {
                    const selectedText = ctrl.translatedText.find((lang) => lang.CultureId === newVal?.CultureId);

                    ctrl.translateDisabled = !newVal ||
                        ((newVal?.Title ?? '').trim() === (selectedText?.Title ?? '').trim() &&
                            (newVal?.Body ?? '').trim() === (selectedText?.Body ?? '').trim())
                        || ctrl.content.selectedLanguages.length < 2;

                    if (newVal && !ctrl.translateDisabled) {
                        ctrl.content.selectedLanguages[ctrl.content.selectedLanguageIndex].AutoTranslated = ctrl.translateDisabled;
                    }
                }, true)

            ctrl.dataLoaded = true;
            
            !ctrl.cultures?.length && languageLoaded();
            !ctrl.showPermissionsEditor && permissionLoaded();

            loadingWatcher = $scope.$watchGroup(['ctrl.isPermissionLoaded', 'ctrl.isLanguageLoaded'], () => {
                if (ctrl.isLanguageLoaded && ctrl.isPermissionLoaded) {
                    initPostChangeWatcher();
                    initCategoryChangeWatcher();
                }
            });
        }

        function close() {
            popup.remove();
            $element.remove();
            postChangeWatcher && postChangeWatcher();
            loadingWatcher && loadingWatcher();
            categoryWatcher && categoryWatcher();
        }

        function languageLoaded() {
            ctrl.isLanguageLoaded = true;
        }

        function permissionLoaded() {
            ctrl.isPermissionLoaded = true;
        }

        function update(content) {
            var postObj = ContentCreateService.formatEditData(content, ctrl);

            ctrl.isLoading = true;
            Page.startLoading();

            $http.post('/ContentModule/Update', postObj).then(() => {
                ctrl.isLoading = false;
                close()
                Page.stopLoading();

                Page.getCurrentPage()?.name === 'contentItem' ? Page.stateReload() : ctrl.onUpdate();
            }, onError);
        }

        function create(content) {
            var postObj = ContentCreateService.formatCreateData(content, ctrl);
            ctrl.isLoading = true;
            Page.startLoading();

            $http.post('/ContentModule/Create', postObj).then(() => {
                ctrl.isLoading = false;
                close();
                Page.stopLoading();
                ctrl.onUpdate();

                if (ctrl.settings.SwipeToRead) {
                    // increase badge
                    var contentCategoryToken = ctrl.settings.IsCategoryViewEnabled ? null : ctrl.CategoryToken;
                    Menu.removeActivity(-1, "content", ctrl.AccountModuleToken, contentCategoryToken);
                    Menu.removeLandingBadge(-1, ctrl.navigationBadge.AccountModuleId, ctrl.navigationBadge.ModuleId,
                        ctrl.navigationBadge.ModuleItemId);
                }
            }, onError);
        }

        function initPostChangeWatcher() {
            let initializing = true;

            postChangeWatcher = $scope.$watch(()=> ctrl.content,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }
        
        function initCategoryChangeWatcher() {
            let initializing = true;
            
            categoryWatcher = $scope.$watch(()=> ctrl.CategoryToken,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function save(content) {
            if (!ctrl.isFormValid || !ctrl.form.$valid) {
                ctrl.showValidation = true;
                $timeout(function () {
                    $element.find('multiple-permission-selector-wrapper.ng-invalid').length &&
                    $element.find('.scroll-container').scrollTop($element.find('multiple-permission-selector-wrapper.ng-invalid')[0].offsetTop);
                });

                return false;
            }

            if (ctrl.mode === 'edit') {
                update(content)
            } else {
                content.ForceInstantPush = false;
                if (content.SendPushNotification === true
                    && content.AllowIgnoringTimeFilterEnabled
                    && !content.IsTimeInTimeframes) {
                    var timeNotes = '';
                    if (content.Schedule.IsActive) {
                        var start = new Date();
                        start.setHours(content.Schedule.Start.Hours);
                        start.setMinutes(content.Schedule.Start.Minutes);
                        var startDate = moment(start).format('HH:mm');
                        var end = new Date();
                        end.setHours(content.Schedule.End.Hours);
                        end.setMinutes(content.Schedule.End.Minutes);
                        var endDate = moment(end).format('HH:mm');
                        timeNotes = startDate + '-' + endDate;
                    } else {
                        timeNotes = "Not allowed"
                    }
                    ConfirmPopupService.open({
                        message: $filter('translate')('CONTENT.TIMEFRAMES'),
                        iconName: 'clock',
                        notes: timeNotes
                    }).then(function () {
                        content.ForceInstantPush = true;
                    }).finally(function () {
                        create(content);
                    });
                } else {
                    create(content);
                }
            }
        }

        function onError(response) {
            ctrl.isLoading = false;
            if (response?.data?.ShowContentSafetyWarning) {
                ContentSafetyWarningService
                    .openPopup(response.data.SeverityLevel, response.data.Action, 'CONTENT.EDIT', () => {
                        ctrl.ignoreContentSafetyWarning = true;
                        save(ctrl.content);
                    })
            } else {
                var errorMessage = response.data.ErrorMessage,
                    key = (errorMessage && errorMessage.length) ? errorMessage : 'ERROR.CONTACT',
                    translation = $filter('translate')(key);

                translation = translation.replace('[MINPOINTRANGE]', response.data.MinPointRange);
                translation = translation.replace('[MAXPOINTRANGE]', response.data.MaxPointRange);

                ToastFactory.error(translation);
                Page.stopLoading();
            }
        }

        function updateEndTime() {
            if (ctrl.content.StartTime) {
                ctrl.content.EndTime = moment(ctrl.content.StartTime).add(1, 'M').toDate();
            }
        }

        function isTextFilled() {
            return ctrl.content.Title && ctrl.content.Title.length && ctrl.content.Body && ctrl.content.Body.length;
        }

        function translate() {
            if (ctrl.translatedText?.length && isShowTranslationChangeWarning()) {
                const currentCultureId = ctrl.content.selectedLanguages[ctrl.content.selectedLanguageIndex]?.CultureId;
                const languages = ctrl.content.selectedLanguages
                    .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== currentCultureId)
                    .map(culture => culture.Name).join(', ');
                TranslationService.confirmTranslations(languages).then(makeTranslation);
            } else {
                makeTranslation();
            }
        }

        function makeTranslation() {
            TranslationService.translateContent(ctrl.AccountModuleToken, ctrl.content.selectedLanguages, ctrl.content.selectedLanguageIndex)
                .then(({translations, excludedWords}) => {
                    ctrl.content.selectedLanguages = ctrl.content.selectedLanguages.map(language => ({
                        ...language,
                        AutoTranslated: false
                    }));
                    ctrl.translateDisabled = true;

                    if (translations?.length) {
                        translations.forEach(function (translation) {
                            const selectedLanguageItem = ctrl.content.selectedLanguages
                                .find(language => language.CultureId === translation.CultureId);
                            selectedLanguageItem.AutoTranslated = true;

                            if (translation.Title) {
                                selectedLanguageItem.Title = translation.Title;
                                selectedLanguageItem.initialTitle = translation.Title;
                            }
                            if (translation.BodyText) {
                                selectedLanguageItem.Body = translation.BodyText;
                                selectedLanguageItem.initialBody = translation.BodyText;
                            }
                            if (ctrl.content.selectedLanguages[ctrl.content.selectedLanguageIndex].mentions &&
                                ctrl.content.selectedLanguages[ctrl.content.selectedLanguageIndex].mentions.length) {
                                selectedLanguageItem.mentions = _.cloneDeep(ctrl.content.selectedLanguages[0].mentions);
                            }
                        });

                        ctrl.translatedText = _.cloneDeep(ctrl.content.selectedLanguages);
                    }

                    ctrl.translationExcludedWords = excludedWords;
                    ctrl.content.selectedLanguages[ctrl.content.selectedLanguageIndex].AutoTranslated = false;
                })
        }

        function isShowTranslationChangeWarning() {
            return ctrl.content.selectedLanguages.some(lang => {
                const item = ctrl.translatedText?.find((item) => item.CultureId === lang.CultureId);
                return lang.Title?.trim() !== item?.Title?.trim() ||
                    lang.Body?.trim() !== item?.Body?.trim();
            })
        }
    }
})();
