(function () {
    'use strict';

    angular.module('App')
        .component('chat', {
            template: require('./ChatComponent.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                newChannelData: '<',
                selectedChannelId: '<'
            },
            controller: ['$rootScope', 'HeaderButtonsFactory', 'ChatPopupsService', 'ChatDataService',
                'ChatConnectionService', 'ActionSheetService', ChatController]
        });

    function ChatController($rootScope, HeaderButtonsFactory, ChatPopupsService, ChatDataService,
                            ChatConnectionService, ActionSheetService) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.isLoaded = false;

            if (ctrl.newChannelData) {
                $rootScope.$broadcast('CHAT.OPEN_FROM_PUSH');
                ChatDataService
                    .createChannel([ctrl.newChannelData.user], ctrl.newChannelData.appLinkId, ctrl.newChannelData.hideAuthor)
                    .then(ChatConnectionService.openChannel)
            }

            if (ctrl.selectedChannelId) {
                $rootScope.$broadcast('CHAT.OPEN_FROM_PUSH');
                ChatConnectionService.openChannel(ctrl.selectedChannelId);
            }

            ChatDataService.getUserChannels().then(function (channelData) {
                HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                ChatDataService.setBlockedIds(channelData.blockedUsers);
                ctrl.blockedUsers = channelData.blockedUsers;
                ctrl.userChannels = channelData.channels;
                ctrl.isLoaded = true;
            })
        }

        function getHeaderButtons() {
            return [
                {
                    icon: 'options-app',
                    onClick: function () {
                        showOptions();
                    }
                },
                {
                    icon: 'chat-create',
                    onClick: function () {
                        openCreateChat();
                    }
                }
            ];
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            ChatDataService.setBlockedIds(null);
            ctrl.listener && ctrl.listener();
        }

        function showOptions() {
            let buttons = [];
            if ($rootScope) {
                buttons.push({
                    text: 'CHAT.ACTIONSHEET.MARK_ALL_READ',
                    icon: 'comment-check',
                    onClick: function () {
                        markAllAsRead();
                    }
                })
            }
            
            function markAllAsRead() {
                ChatDataService.markAllChannelsAsRead()
                    .then(() => $rootScope.$broadcast('CHAT.MARK_ALL_CHANNELS_READ'));
            }
            
            const actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {
                name: 'chat-channels',
            });
            actionSheet.show();
        }

        function openCreateChat() {
            ChatPopupsService.openCreateChatPopup();
        }
    }
})();