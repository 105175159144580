(function () {
    'use strict';

    angular.module('App')
        .factory('ChatPopupsService', ['PopupWrapperService', ChatPopupsService]);

    function ChatPopupsService(PopupWrapperService) {

        return {
            openCreateChatPopup: openCreateChatPopup,
            openConversationPopup: openConversationPopup,
            openChatSettingsPopup: openChatSettingsPopup,
            openAddUsersPopup: openAddUsersPopup,
            openMessageViewersPopup: openMessageViewersPopup,
            openChatMembersPopup: openChatMembersPopup,
            disableBodyScrollInChat: disableBodyScrollInChat
        };

        function openCreateChatPopup() {
            PopupWrapperService.createDynamic('<create-chat></create-chat>');
        }

        function openChatMembersPopup(channel, closeConversation, closeChatSettings) {
            PopupWrapperService
                .createDynamic(
                    `<chat-members 
                        channel="channel"
                        close-conversation="closeConversation" 
                        close-chat-settings="closeChatSettings"
                    ></chat-members>`, {
                channel, closeConversation, closeChatSettings
            });
        }

        function openConversationPopup(channelId, channel, updateChannelMembersOnClose) {
            PopupWrapperService.createDynamic(
                '<conversation channel-id="channelId" channel="channel" close-channel="closeChannel"></conversation>',
                {
                    channelId: channelId,
                    channel: channel,
                    closeChannel: updateChannelMembersOnClose
                }
            );
        }

        function openChatSettingsPopup(channel, closeConversation) {
            PopupWrapperService
                .createDynamic(
                    `<chat-settings
                        channel="channel" 
                        close-conversation="closeConversation"
                    ></chat-settings>`, {
                channel: channel,
                closeConversation: closeConversation            
            });
        }

        function openAddUsersPopup(channel, closePreviousPopup) {
            PopupWrapperService
                .createDynamic(
                    `<add-users 
                        channel="channel" 
                        close-previous="closePrevious" 
                    ></add-users>`,
                {
                    channel: channel,
                    closePrevious: closePreviousPopup,
                });
        }

        function openMessageViewersPopup(channelId, messageId, userId, blockedUsers) {
            PopupWrapperService
                .createDynamic(
                    `<message-viewers 
                                    channel-id="{{ channelId }}"
                                    message-id="{{ messageId }}"
                                    current-user-id="{{ userId }}"
                                    blocked-users="blockedUsers"
                                ></message-viewers>`,
                    {
                        channelId: channelId,
                        messageId: messageId,
                        userId: userId,
                        blockedUsers: blockedUsers,
                    }
                );
        }

        function disableBodyScrollInChat() {
            const popups = document.querySelectorAll('popup-wrapper');
            if (!popups) return;

            popups.forEach(popup => {
                const parent = popup.parentElement;
                if (parent && parent.tagName.toLowerCase() === 'conversation') {
                    document.body.style.overflow = 'hidden';
                }
            });  
        }
    }
})();